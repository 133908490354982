.container-markdown {
  @apply max-w-full text-gray-900;
}

.container-markdown img {
  @apply object-contain w-full max-w-full mx-auto my-8 rounded-md;
}

.container-markdown strong {
  @apply font-extrabold;
}

.container-markdown u {
  @apply underline;
}

.container-markdown p {
  @apply mb-5 text-sm text-justify lg:text-base;
}

.container-markdown a {
  @apply text-blue-600 underline;
}

.container-markdown ul {
  @apply mb-5 ml-5 list-disc;
}

.container-markdown ul li p {
  @apply py-1 m-0;
}

.container-markdown ol {
  @apply mb-5 ml-5 list-decimal;
}

.container-markdown pre {
  @apply px-5 mb-5 whitespace-pre-wrap rounded-md;
}

.container-markdown code {
  @apply px-5 mb-5 whitespace-pre-wrap rounded-md;
}

.container-markdown h1 {
  @apply mb-3 text-2xl font-bold text-justify lg:text-3xl mt-7;
}

.container-markdown h2 {
  @apply mb-3 text-xl font-bold text-justify lg:text-2xl mt-7;
}

.container-markdown h3 {
  @apply mb-3 text-lg font-bold text-justify lg:text-xl mt-7;
}

.container-markdown h4 {
  @apply mb-3 text-base font-bold text-justify lg:text-lg mt-7;
}

.container-markdown h5 {
  @apply mb-3 text-base font-bold text-justify mt-7;
}

.container-markdown h6 {
  @apply my-3 text-base font-bold text-justify;
}
